import { MantineProvider }                          from "@mantine/core";
import { MantineEmotionProvider, emotionTransform } from "@mantine/emotion";

import { useGetDarkMode, useGetDeviceType } from "_common/hooks/global";
import { checkIsBrowser, isAppleDevice }    from "_common/utils";
import { emotionCache }                     from "_styles/createEmotionCache";
import { dark, light, mantineTheme }        from "_styles/theme";

const ThemeWrapper = props => {
	const deviceType        = useGetDeviceType ();
	const isMobile          = props?.isMobile || deviceType === "mobile";
	const isWebview         = props?.isWebview;
	const darkModeCookie    = props?.darkMode === undefined ? false : props?.darkMode;
	const darkMode          = !checkIsBrowser () || isWebview ? darkModeCookie : useGetDarkMode ();
	const themeColorPalette = darkMode ? dark : light;
	const isApple           = isAppleDevice ();

	const theme = mantineTheme ( { isApple, themeColorPalette, isMobile, darkMode } );

	if ( props?.withTheme ) {
		return (
			<MantineEmotionProvider cache = { emotionCache }>
				<MantineProvider
					defaultColorScheme = "auto"
					forceColorScheme   = { props?.forceTheme ? props?.forceTheme : darkMode ? "dark" : "light" }
					stylesTransform    = { emotionTransform }
					theme              = { theme }
				>
					{props.children}

				</MantineProvider>
			</MantineEmotionProvider>
		);
	}

	return props.children;
};

export default ThemeWrapper;